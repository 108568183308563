import {createRouter, createWebHistory} from "vue-router";
import LoginView from "@/components/Auth/Login.vue"
import DashboardListView from "@/views/Dashboard/DashboardsListView.vue";
import SitemapsDataView from "@/views/Sitemap/SitemapsView.vue";
import UrlData from "@/views/Sitemap/SitemapDataView.vue";
import IncidencesView from "@/views/Incidences/IncidencesView.vue";
import DashboardView from "@/views/Dashboard/DashboardView.vue";
import PageNotFoundView from "@/views/PageNotFoundView";
import ManagerRolesView from "@/views/Admin/ManagerRolesView.vue";
import IncidencesManualCategorizationView from "@/views/Incidences/IncidencesManualCategorizationView.vue";
import ManagerDashboardView from "@/views/Admin/ManagerDashboardView.vue";
import ManagerAdminView from "@/views/Admin/ManagerAdminView.vue";
import ManagerGroupsRolesView from "@/views/Admin/ManagerGroupsRolesView.vue";
import IncidencesEnrollmentsView from "@/views/Incidences/IncidencesEnrollmentsView.vue";
import ReportUserView from "@/views/ConsultingReport/ReportUserView.vue";
import AccessDenyView from "@/views/AccessDenyView.vue";
import InconsistenciesView from "@/views/Inconsistencies/InconsistenciesView.vue";
import ProductVideoView from "@/views/ProductVideo/ProductVideoView.vue";
import ImportPayrollView from "@/views/Payroll/ImportPayrollView.vue";
import DocumentsView from "@/views/Documents/DocumentsView.vue";
import ObjectiveForcesSalesView from "@/views/SalesForce/ObjectiveForcesSalesView.vue";
import ChartsFromJSONView from "@/views/Charts/ChartsFromJSONView.vue";
import AssignmentFFVVToEnrollmentsView from "@/views/SalesForce/AssignmentFFVVToEnrollmentsView.vue";
import GenerationOfCourseScriptsView from "@/views/ProductVideo/GenerationOfCourseScriptsView.vue";
import RelatedKeywords from "@/views/SearchEngine/RelatedKeywords.vue";
import TasksAITrends from "@/views/SearchEngine/TasksAITrends.vue";
import HTMLTraductionView from "@/views/Translator/HTMLTraductionView.vue";
import DashboardAccessPetitionView from "@/views/Dashboard/DashboardAccessPetitionView.vue";
import DashboardManageAccessPetitionsView from "@/views/Dashboard/DashboardManageAccessPetitionsView.vue";


const routes = [

    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
            beauty_name: 'Inicio sesión',
        }
    },

    /* Dashboards */
    {
        path: "/",
        name: "index",
        component: DashboardListView,
        meta: {
            beauty_name: 'Lista de tableros',
        }
    },

    {
        path: "/dashboards/:id",
        name: "dashboards",
        component: DashboardView,
        meta: {
            beauty_name: 'Tablero',
        }
    },
    {
        path: "/dashboard-asesores-academicos",
        name: "dashboards-asesores-academicos",
        component: ChartsFromJSONView,
        meta: {
            beauty_name: 'Dashboard asesores académicos',
        }
    },
    {
        path: "/incongruencias",
        name: "incongruencias",
        component: InconsistenciesView,
        meta: {
            beauty_name: 'Incongruencias en facturación',
        }
    },
    {
        path: "/creacion-video-producto",
        name: "creacion-video-producto",
        component: ProductVideoView,
        meta: {
            beauty_name: 'Creación automática video producto',
        }
    },

    {
        path: "/importacion-nominas",
        name: "importacion-nominas",
        component: ImportPayrollView,
        meta: {
            beauty_name: 'Importación nóminas',
        }
    },

    {
        path: "/generacion-guiones-para-cursos",
        name: "generacion-guiones-para-cursos",
        component: GenerationOfCourseScriptsView,
        meta: {
            beauty_name: 'Generación de guiones para cursos',
        }
    },
    /*{
        path: "/revision-traducciones-html",
        name: "obtener-html-traducciones",
        component: HTMLTraductionView,
        meta: {
            beauty_name: 'Generación de HTML de traducciones',
        }
    },*/

    /* FIN Dashboards */

    /* incidencias */
    {
        path: "/incidencias",
        name: "incidencias",
        component: IncidencesView,
        meta: {
            beauty_name: 'incidencias',
        }
    },

    {
        path: "/incidencias-manual",
        name: "incidencias-manual",
        component: IncidencesManualCategorizationView,
        meta: {
            beauty_name: 'Categorización de incidencias manualmente',
        }
    },

    {
        path: "/registrar-incidencia-matricula",
        name: "registrar-incidencia-matricula",
        component: IncidencesEnrollmentsView,
        meta: {
            beauty_name: 'Incidencias matrículas',
        }
    },
    /* FIN Incidencias */
    /* Buscador */
    {
        path: "/keywords-relacionadas",
        name: "buscar-keywords-relacionadas",
        component: RelatedKeywords,
        meta: {
            beauty_name: 'Keywords Relacionadas',
        }
    },
    {
        path: "/tareas-aitrends",
        name: "tareas-aitrends",
        component: TasksAITrends,
        meta: {
            beauty_name: 'Tareas AITRENDS',
        }
    },
    /* FIN Buscador */

    /* Mapa del sitio */
    {
        path: "/sitemaps-data",
        name: "sitemaps-data",
        component: SitemapsDataView,
        meta: {
            beauty_name: 'Datos del mapa del sitio',
        }
    },

    {
        path: "/urls-sitemap",
        name: "urls-sitemap",
        component: UrlData,
        meta: {
            beauty_name: 'Datos de una url',
        }

    },
    /* FIN Mapa del sitio */
    /* Usuario */
    {
        path: "/informe-usuario/:id_persona",
        name: "informe-usuario",
        component: ReportUserView,
        meta: {
            beauty_name: 'Informe de Usuario',
        }
    },
    /* FIN Usuario */

    /* Administración */
    {
        path: "/admin",
        name: "admin",
        component: ManagerAdminView,
        meta: {
            beauty_name: 'Panel de administración',
        }
    },

    {
        path: "/admin/gestor-roles",
        name: "gestor-roles",
        component: ManagerRolesView,
        meta: {
            beauty_name: 'Gestión de roles',
        }
    },

    {
        path: "/admin/gestor-dashboard-roles",
        name: "gestor-dashboard-roles",
        component: ManagerDashboardView,
        meta: {
            beauty_name: 'Gestión de roles por dashboard',
        }
    },

    {
        path: "/admin/gestor-grupos-roles",
        name: "gestor-grupos-roles",
        component: ManagerGroupsRolesView,
        meta: {
            beauty_name: 'Gestión de roles por grupo',
        }
    },
    {
        path: "/peticion-acceso-dashboard",
        name: "peticion-acceso-dashboard",
        component: DashboardAccessPetitionView,
        meta: {
            beauty_name: 'Petición de acceso a Dashboard',
        }
    },
    {
        path: "/gestion-peticion-acceso",
        name: "gestion-peticion-acceso",
        component: DashboardManageAccessPetitionsView,
        meta: {
            beauty_name: 'Petición de acceso a Dashboard',
        }
    },
    /* FIN Administración */

    /* Fuerza de ventas*/
    {
        path: "/objetivos-fuerza-ventas",
        name: "objetivos-fuerza-ventas",
        component: ObjectiveForcesSalesView,
        meta: {
            beauty_name: 'Objetivos para fuerza de ventas',
        }
    },
    {
        path: "/asignacion-fuerza-ventas-a-matriculas",
        name: "asignacion-fuerza-ventas-a-matriculas",
        component: AssignmentFFVVToEnrollmentsView,
        meta : {
            beauty_name: 'Asignación fuerza de ventas a matrículas',
        }
    },
    /* FIN Fuerza ventas*/

    {
        path: "/documentacion",
        name: "documentacion",
        component: DocumentsView,
        meta: {
            beauty_name: 'Documentación AICORE',
        }
    },


    {
        path: "/acceso-denegado",
        name: "not-allowed",
        component: AccessDenyView,
        meta: {
            beauty_name: 'Acceso denegado',
        }
    },

    /* PÁGINA 404 */
    {
        path: "/:pathMatch(.*)*",
        name: "page-not-found",
        component: PageNotFoundView,
        meta: {
            beauty_name: 'Página no encontrada',
        }
    },


];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router;


