import {Endpoints, fetchToken} from "@/utils/auth";
import {showNotification} from "@/utils/aux_functions";
import axios from "axios";

export async function getLookerToken(endpoint = Endpoints.LOOKER.name) {
    // Esta función nos permite obtener el token de autentificación de AICORE
    let tokenAicore = null;
    try {
        tokenAicore = await fetchToken(endpoint);
        tokenAicore = tokenAicore['access_token'];
    } catch (e){
        showNotification('error', 'Error al autentificarse con el servidor de aicore');
        return null;
    }
    return tokenAicore;
}

/**
 * @param type (string) => GET, POST. Obligatorio
 * @param url (string) => URL DEL WS. Obligatorio
 * @param data (array) => PARÁMETROS QUE NECESITA EL WS. Obligatorio
 * @param petition (string) => AJAX, AXIOS. Opcional
 * @param content_type (string) Opcional
 * @returns {Promise}
 */
export async function aicoreLookerBackendPetition(type, url, data, {petition = 'ajax', content_type = 'application/x-www-form-urlencoded; charset=UTF-8'} = {}) {
    let token = await getLookerToken();

    let env_url = process.env.VUE_APP_AICORE_LOOKER_URL_API;

    switch (petition.toLowerCase()) {
        case 'ajax':
            return $.ajax({
                type: type,
                url: env_url + url,
                data: data,
                headers: {
                    'content-type': content_type,
                    'Authorization': 'Bearer ' + token
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr);
                    console.log('thrownError: ' + thrownError);
                }
            });
        case 'axios':
            switch (type.toLowerCase()) {
                case 'post':
                    return axios.post(env_url + url,
                        data,
                        {
                            headers: {
                                'content-type': content_type,
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    )
                case 'get':
                    return axios.get(env_url + url + data,
                        {
                            headers: {
                                'content-type': content_type,
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    )
            }
    }
}
